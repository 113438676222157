<template>
    <footer>
        <div class="box-container">
            <div class="footer-container">
                <div class="col-25 col-menu">
                    <nav>
                        <router-link class="menu-link" :to="{name : 'Home'}">Home</router-link>
                        <router-link class="menu-link" :to="{name : 'NFT'}">NFT</router-link>
                        <router-link class="menu-link" :to="{name : 'Collections'}">Collections</router-link>
                        <router-link class="menu-link" :to="{name : 'Metaverse'}">Metaverse</router-link>
                        <router-link class="menu-link" :to="{name : 'Contact'}">Contact</router-link>
                    </nav>
                </div>
                <div class="col-50">
                    <img src="./../assets/logo-b2m-footer.png" class="logo-footer" />
                </div>
                <div class="col-25">
                    <div class="footer-infos">
                        <p>+33 (0)4 86 31 53 84</p>
                        <!-- <p><a href="mailto:contact@synthes3d.com">contact@synthes3d.com</a></p> -->
                        <footer-info city="Aix-en-Provence"></footer-info>
                        <footer-info city="Angers"></footer-info>
                        <footer-info city="USA"></footer-info>
                    </div>
                </div>
            </div>
            <div class="copyright-container">
                <p>© Copyright {{ currentYear }} - <a href="https://www.synthes3d.com/fr/" target="_blank">SYNTHES'3D</a> - All rights reserved - <a href="https://www.synthes3d.com/fr/mentions-legales/" target="_blank">Mentions légales</a> - Design & Development by <a href="https://www.synthes3dweb.com/" target="_blank">SYNTHES'3D WEB</a></p>
            </div>
        </div>
    </footer>
</template>

<script>
import FooterInfo from "./FooterInfo.vue";

export default {
    name : "Footer",
    components : { FooterInfo},
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

footer {
    background:#000;
    display: flex;
    flex-direction: column;
    align-items: center;
        position: relative;
    z-index: 1;
}

footer p {
    color: #fff;
    font-weight:300;
    margin:5px;
}

footer p a {
    color:#fff;
    text-decoration: none;
    transition: 0.5s;    
}

footer p a:hover {
    text-decoration: underline;
}

.footer-container {
    display:flex;
    align-items: center;
    margin:80px 0 40px 0;
}

footer nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
} 

footer nav .menu-link {
    color:#fff;
    text-decoration: none;
    transition: 0.5s;
    margin: 5px 0;
    font-weight:300;
    font-size:15px;
    position:relative;
}

footer nav .menu-link:hover:after {
    position: absolute;
    content: "";
    height:2px;
    bottom:-5px;
    left:0;
    right:0;
    background: $mainGradient;
}

.col-50 {
    display: flex;
    justify-content: center;
}

.col-50 img {
    max-width: 90%;
}

.col-25:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.copyright-container {
    padding:10px;
}

.copyright-container p {
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
}

@media screen and (max-width:768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .col-menu {
        display: none;
    }

    .col-25, .col-50  {
        width:100%;
    }

    .logo-footer {
        max-width: 250px;
    }

    .col-25:last-child  {
        align-items: center;
        margin-top:20px;
    }
}

</style>