import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";


const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta : {
      title: "Born2Mint - Your NFT Maker & Metaverse Guide",
      desc : "Born2Mint accompagne les marques, les institutions, les talents, les artistes, les célébrités et les sportifs de haut niveau à lancer et à commercialiser des collections NFT ainsi qu’à basculer dans le Metaverse"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta : {
      title: "Contactez-nous pour échanger sur votre projet NFT et Metaverse",
      desc : "Born2Mint demeure à votre entière disposition pour échanger sur vos réflexions et projets autour des NFT, du Metaverse et du Web3"
    }
  },
  {
    path: "/nft",
    name: "NFT",
    component: () => import("../views/NFT.vue"),
    meta : {
      title: "Votre Expert NFT pour vous épauler dans vos projets NFT et Web 3.0",
      desc : "Born2Mint vous accompagne sur 360° dans la conception, la création, le minting, la commercialisation et la promotion de vos Collections de NFT"
    }
  },
  {
    path: "/collections",
    name: "Collections",
    component: () => import("../views/Collections.vue"),
    meta : {
      title: "Créateur & Editeur de collections NFT",
      desc : "Born2Mint lance ses propres collections de NFT et certaines sont notamment destinées à soutenir des Causes"
    }
  },
  {
    path: "/metaverse",
    name: "Metaverse",
    component: () => import("../views/Metaverse.vue"),
    meta : {
      title: "Votre Guide-Expert dans le Metaverse afin de vous aider à basculer dans ces univers Web 3.0 nouvelle génération",
      desc : "Born2Mint vous accompagne de A à Z dans votre projet Metaverse : The Sandbox, Decentraland, Bloktopia, Cryptoverse, etc"
    }
  },
  // {
  //   path: "/legal-notices",
  //   name: "LegalNotices",
  //   component: () => import("../views/LegalNotices.vue"),
  //   meta : {
  //     title: "BORN2MINT",
  //     desc : "desc"
  //   }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})
trackRouter(router);

export default router
