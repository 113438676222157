<template>
    <div class="footer-info">
        <div class="footer-info-icon"></div>
        <p class="footer-info-city">{{ city }}</p>
    </div>
</template>

<script>
export default {
    name : "FooterInfo",
    props : ["city"]
}
</script>

<style scoped lang="scss">


.footer-info {
    display: flex;
    align-items: center;
    margin:10px 0;
}

.footer-info-icon {
    margin-right:10px;
    height:20px;
    width:20px;
    background-image:url("./../assets/icon-map.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.footer-info-city {
    color:#fff;
    text-transform: uppercase;
    margin:0;
}
</style>