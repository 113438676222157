<template>
    <header :class="type">
        <div class="box-container">
            <router-link :to="{name : 'Home'}" class="logo-container">
                <img :src="logo" alt="logo Born2Mint" class="logo" />
            </router-link>
            <div class="menu-container" @mouseleave="closeAll()">
                <nav>
                    <router-link class="menu-link" :to="{name : 'Home'}" @mouseenter="closeAll()">Home</router-link>
                    <router-link class="menu-link" :to="{name : 'NFT'}" @mouseenter="openNft()" >NFT
                        <div class="sub" v-if="nft">
                            <router-link class="menu-link-sub" :to="{name : 'NFT', hash:'#def'}">Définition</router-link>
                            <router-link class="menu-link-sub" :to="{name : 'NFT', hash:'#advantage'}">Avantages</router-link>
                            <router-link class="menu-link-sub" :to="{name : 'NFT', hash:'#criteria'}">Critères de réussite</router-link>
                            <router-link class="menu-link-sub" :to="{name : 'NFT', hash:'#follow'}">Notre accompagnement</router-link>
                        </div>
                    </router-link>
                    <router-link class="menu-link" :to="{name : 'Collections'}" @mouseenter="closeAll()">Collections</router-link>
                    <router-link class="menu-link" :to="{name : 'Metaverse'}" @mouseenter="openMetaverse()">Metaverse
                           <div class="sub" v-if="metaverse">
                            <router-link class="menu-link-sub" :to="{name : 'Metaverse', hash:'#def'}">Définition</router-link>
                            <router-link class="menu-link-sub" :to="{name : 'Metaverse', hash:'#follow'}">Notre accompagnement</router-link>

                        </div>
                    </router-link>
                    <router-link class="menu-link" :to="{name : 'Contact'}" @mouseenter="closeAll()">Contact</router-link>
                </nav>    
            </div>
            <!-- MENU RESP -->
            <div class="menu-header-resp">
                <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
                    <router-link class="menu-link" :to="{name : 'Home'}" >Home</router-link>
                    <router-link class="menu-link" :to="{name : 'NFT'}" >NFT</router-link>
                    <router-link class="menu-link" :to="{name : 'Collections'}" >Collections</router-link>
                    <router-link class="menu-link" :to="{name : 'Metaverse'}" >Metaverse</router-link>
                    <router-link class="menu-link" :to="{name : 'Contact'}" >Contact</router-link>                    
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name : "Header",
    data() {
        return {
            type : null,
            logo : require("@/assets/logo-b2m-footer.png"),
            nft : false,
            metaverse: false
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        this.getType();
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
         handleScroll() {
             this.getType();
        },
        getType() {
            if(window.pageYOffset < 100) {
                this.type = null;
                this.logo = require("@/assets/logo-b2m-footer.png");
            }else {
                this.type = "scroll";
                this.logo = require("@/assets/logo-b2m.png");
            }
        },
        closeAll() {
            this.nft = false;
            this.metaverse = false;
        },
        openNft() {
            this.nft = true;
            this.metaverse = false;
        },
        openMetaverse() {
            this.nft = false;
            this.metaverse = true;
        }
    },
    watch  : {
        '$route.name' : function() {
            this.getType();
            this.closeAll();
        } 
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

header {
    height:110px;
    background:transparent;
    position: fixed;
    top:0;
    width:100%;
    display: flex;
    justify-content: center;
    transition:0.5s;
    z-index:9;
}

header.scroll {
    height:80px;
    background:#000;
}

header.scroll:after {
    position: absolute;
    content:"";
    bottom:0;
    left:0;
    right:0; 
    height:1px;
    background:$mainGradient;
}

header .logo {
    height:80px;
    transition:0.8s;
}

header.scroll .logo {
    height:50px;
    width:auto;
    transition:0.5s;
}

.menu-container {
    height:110px;
    display: flex;
    align-items: center;
}

.box-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-link {
    color:#fff;
    font-size:20px;
    padding:0 25px;
    text-decoration: none;
    font-weight:300;
    transition:0.5s;
    position:relative;
}

header.scroll .menu-link {
    font-size:18px;
}

// .menu-link:hover {
//     font-weight:500;
// }

.menu-link:hover:after {
    position: absolute;
    content: "";
    height:2px;
    bottom:-5px;
    left:25px;
    right:25px;
    background: $mainGradient;
}

.sub {
    display:flex;
    flex-direction: column;
    position:absolute;
    top:50px;
    left:0;
    width:250px;
    background:#000;
    z-index: 99;
}

.sub .menu-link-sub {
    font-size:18px;
    padding:5px 10px;
    color:#fff;
    text-decoration: none;
    font-weight:300;
    transition:0.5s;
}

.sub .menu-link-sub:hover{
    background:$mainGradient;
}

sub .menu-link-sub:hover {
    width:0;
}

/* MENU RESP */
.menu-header-resp {
    display: none;
}

.burger-nav {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.burger-nav span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: #fff;
    border-radius: 7px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.burger-nav span:nth-child(1) {
    top: 0px;
}

.burger-nav span:nth-child(2),
.burger-nav span:nth-child(3) {
    top: 14px;
}

.burger-nav span:nth-child(4) {
    top: 28px;
}

.burger-nav.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.burger-nav.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

.burger-nav.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.burger-nav.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

.menu-header-resp-content {
    position: fixed;
    top: 85px;
    width: 300px;
    right: 0;
    background: #000;
    border-bottom: 1px solid #fff;
    display: flex;
    flex-direction: column;
}

.menu-header-resp-content .menu-link {
    width: calc(100% - 40px);
    border: none;
    height: 40px;
    justify-content: flex-start;
    padding: 0 20px;
    align-items: center;
    text-decoration: none;
    display: flex;
}


@media screen and (max-width:1024px) {
    .menu-link {
        padding:0 10px;
        font-size:18px;
    }

    header .logo {
        height: 60px;
    }
}

@media screen and (max-width:768px) {
    .menu-container {
        display: none;
    }

    .menu-header-resp {
        display: flex;
    }
}
</style>