import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'aos/dist/aos.css';
import Particles from "particles.vue3";
import VTooltipPlugin from 'v-tooltip';
import VueGtag from "vue-gtag-next";

createApp(App).use(store).use(Particles).use(router).use(VTooltipPlugin).use(VueGtag, {
    property: {
      id: "G-XE0HHX8QKQ"
    }
  }).mount('#app');
