import { createStore } from "vuex";

export default createStore({
  state: {
    menu_open : false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
