<template>
  <header-app></header-app>
  <router-view></router-view>
  <!-- <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view> -->
  <footer-app></footer-app>
</template>

<script>
import HeaderApp from "./components/HeaderApp.vue";
import FooterApp from "./components/FooterApp.vue";
import store from "./store/index";

export default {
  name : "App",
  components : { HeaderApp, FooterApp }  ,
  created() {
      this.getMeta(this.$route);
  },
  methods : {
    getMeta(route) {
      document.title = route.meta.title;
      const descEl = document.querySelector('head meta[name="description"]');
      descEl.setAttribute('content', route.meta.desc);
    }
  },
  watch : {
    "$route" (to)  {
        this.getMeta(to);
        store.state.menu_open = false;
    }
  }
}
</script>


<style lang="scss">
@import "@/scss/_variables.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin:0;
  padding:0;
  font-family: 'Open Sans', sans-serif;
  background:#000;
}

.main {
  min-height: 200vh;
  background:#000;
}

h2 {
  color:#fff;
  text-align: center;
  margin:0 0 40px 0;
  font-size:50px;
  text-transform: uppercase;
  font-weight:600;
}

p {
  font-size:18px;
  color:#fff;
  text-align:justify;
  font-weight:300;
  line-height:1.5em;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    margin:20px 0;
    color:#fff;
    font-weight:300;
    font-size:18px;
}

.row {
  display: flex;
}

.box-container {
  width:$box;
  max-width: 95%;
  margin:0 auto;
}

.flex-container {
  display: flex;
  justify-content: flex-start;
  align-items:center;
}


.banner {
  min-height:calc(100vh - 100px);
  // background-image: url("./assets/BG.jpg");
  // background-position: bottom right;
  // background-repeat: no-repeat;
  // background-size: cover;
  background:#000;
  display: flex;
  justify-content: center;
  align-items: center;
  position:relative;
  overflow: hidden;
  padding-top:100px;
}



.main-color {
  color:$mainColor;
}

.second-color {
  color: $secondColor;
}

.btn {
  padding:15px 50px;
  border-radius:40px;
  font-size:25px;
  color:#fff;
  text-transform: uppercase;
  font-weight:400;
  text-decoration: none;
  background: linear-gradient(-45deg, $mainColor, $secondColor, $mainColor, $secondColor);
	background-size: 200% 200%;
	animation: gradient 8s ease infinite;
  transition:0.5s;
  cursor: pointer;
  text-align: center;
  display: flex;
}

.btn:hover {
  // background: rgba(0, 0, 0, 0.5) linear-gradient(-45deg, $mainColor, $secondColor, $mainColor, $secondColor);
  // background-blend-mode: darken;
  background: linear-gradient(-45deg, #9e1871, #16598f, #9e1871, #16598f);
  background-size: 200% 200%;
	animation: gradient 8s ease infinite;
  transition:0.5s;
}

/* columns */

.col-25 {
  width:23%;
  margin:0 1%;
}

.col-50 {
  width:48%;
  margin:0 1%;
}

/* animate view */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  //transform: scale(0.9);
  opacity: 0;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

  /* tooltip */
  .v-popper--theme-tooltip {
    color:#000;
    background:#fff;
    padding:5px 20px;
    border-radius:20px;
    margin: 10px;
    z-index:9999;
  }

  .popper {
      background:#fff !important;
    padding:5px 20px !important;
    border-radius:20px !important;
  }

  .display-mobile {
    display: none;
  }


  @media screen and (max-width:768px) {
    h2 {
      font-size:30px;
    }

    .btn {
      padding:10px 40px;
      border-radius:40px;
      font-size:20px;
    }

    .col-50 {
      width:100%;
    }

    .row {
      flex-direction: column;
    }

    .box-container {
      max-width: 90%;
    }

    .display-mobile {
      display: block;
    }

    .display-desk {
      display: none;
    }
  }

</style>
